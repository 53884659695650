@use "@angular/material" as mat;

$theme: mat.define-theme(
    (
      color: (
        theme-type: dark,
        primary: mat.$violet-palette,
      ),
      typography: (
        brand-family: "Comic Sans",
        bold-weight: 900,
      ),
      density: (
        scale: -1,
      ),
    )
);
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "quill/dist/quill.snow.css";
@import "leaflet/dist/leaflet.css";


* {
  scroll-behavior: smooth;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide-single::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide-single *::-webkit-scrollbar {
  display: block;
}

.scrollbar-hide-single {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide-single * {
  -ms-overflow-style: scrollbar;
  scrollbar-width: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-success {
  background-color: green;
  color: #fff;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.custom-disabled {
  pointer-events: none;
  opacity: 0.5;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #939393;
  border-radius: 5px;
}

.archived {
  pointer-events: none;
  position: relative;
}

.archived * {
  filter: grayscale(100%);
}

.resize-handle-top,
.resize-handle-bottom {
  position: absolute;
  height: 5px;
  cursor: row-resize;
  width: 100%;
}

.resize-handle-top {
  top: 0;
}

.resize-handle-bottom {
  bottom: 0;
}

.resize-handle-left,
.resize-handle-right {
  height: 100%;
  cursor: col-resize;
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

.cdk-drag-preview {
  width: 100%;
  opacity: 0.8;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-placeholder .rectangle {
  background: rgba(0, 0, 0, 0.2);
}

.example-boundary {
  width: 400px;
  height: 400px;
  max-width: 100%;
  border: dotted #ccc 2px;
}

